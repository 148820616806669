import React from 'react';
import {
  faMedium,
  faTwitterSquare,
  faLinkedin,
  faGithubSquare,
  faGitlab,
} from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/social.css';

library.add(faMedium, faTwitterSquare, faLinkedin, faGithubSquare, faGitlab);

const Social = () => (
  <div className="social">
    <a
      href="https://twitter.com/HeberGB"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon
        icon={['fab', 'twitter-square']}
        className="social-icon dark"
      />
    </a>
    <a
      href="https://www.linkedin.com/in/hebergb/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon
        icon={['fab', 'linkedin']}
        className="social-icon dark"
      />
    </a>
    <a
      href="https://github.com/HeberGB"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon
        icon={['fab', 'github-square']}
        className="social-icon dark"
      />
    </a>
    <a
      href="https://gitlab.com/HeberGB"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={['fab', 'gitlab']} className="social-icon dark" />
    </a>
  </div>
);

export default Social;

import React from 'react';
import PropTypes from 'prop-types';
import Particles from 'react-particles-js';

import '../styles/background.css';

const getColor = theme => (theme === 'light' ? '#3741ae' : '#a6960f');

const getConfig = theme => ({
  particles: {
    color: { value: getColor(theme) },
    number: {
      value: 300,
      density: {
        enable: true,
        value_area: 500,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      direction: 'top',
      speed: 0.5,
    },
    size: {
      value: 2,
    },
    opacity: {
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0.05,
      },
    },
  },
  retina_detect: true,
});

const Background = props => {
  const { userTheme } = props;
  return (
    <Particles className="particles-background" params={getConfig(userTheme)} />
  );
};

Background.propTypes = {
  userTheme: PropTypes.string.isRequired,
};

export default Background;
